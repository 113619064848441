export const environment = {
  production: false,
  scheme: "https://",
  host: "api-managment-digitalizcija.azure-api.net/vrc-api-development/v2",
  auth_host: "api-managment-digitalizcija.azure-api.net/vrc-api-development-auth/v2",
  crm_host: "api-managment-digitalizcija.azure-api.net/vrc-api-development",
  offer_host: "api-managment-digitalizcija.azure-api.net/vrc-api-development",
  validation_host: "api-managment-digitalizcija.azure-api.net/vrc-api-development",
  port: "",
  version: 2,
  authVersion: 2,
  crmVersion: 1,
  offerVersion: 1,
  validationVersion: 1,
  app_url: 'https://vrc.dev.valamar.hr',
  web_url:'https://www.valamar-preview.com',
  enableServiceWorker: true,
  theme: "theme-background-dev",
  env_text: "Development",
};
